* {
  margin: 0;
  padding: 0;
}

body {
  margin: 50px;
  background-color: rgb(246, 246, 246);
}

h1, 
h2, 
h3, 
h4, 
h5 {
  font-family: Helvetica, sans-serif;
  letter-spacing: -0.6pt;
}

h1,
h2 {
  font-size: 14pt;
}

h2,
h4 {
  font-weight: 400;
}

h3,
h4,
p,
a,
li {
  font-size: 12pt;
}

p, 
li, 
a {
  font-family: Helvetica, sans-serif;
  letter-spacing: -0.2pt;
}

li {
  list-style: none;
}

a {
  color: black;
  transition: background-color 150ms ease;
}

a:hover {
  background-color: black;
  color: white;
}

.component h2, 
.component p,
.component div,
.component h4 {
  margin-bottom: 30px;
}

nav .underconstruction {
  color: #bbb;
}

#beauyeroc iframe {
  border: 2px solid black;
  width: 250px;
  height: 250px;
}

#home img {
  border: 2px solid black;
  width: 100%;
  height: 100%;
}

.programming-project {
  display: flex;
  flex-direction: row;
}

.programming-project img {
  width: 75px;
  height: 75px;
  margin-right: 20px;
}

#art .exhibitions-div p {
  margin-bottom: 0px;
}

#art img {
  width: 100%;
  margin: 10px 0px;
  border: 2px solid black;
}

#art .sold {
  color: red;
}

#design-project-grid {
  display: flex;
  flex-direction: column;
}

.design-project {
  display: flex;
  flex-direction: column;
  padding: 10px 0px;
}

.design-project img,
.design-project video {
  width: 500px;
  margin: -10px 0px;
}

.design-project h3, 
.design-project p {
  max-width: 600px;
}

footer {
  margin-top: 30px;
}

@media (max-width: 400px) {
  body {
    margin: 30px;
  }
  
  h1, h2, h3 {
    font-size: 12pt;
  }

  nav ul {
    display: flex;
    flex-wrap: wrap;
  }

  nav li {
    margin-right: 10px;
  }

  #works-at-work-pieces img {
    height: 200px;
  }

  .design-project {
    flex-direction: column;
    padding: 0px;
  }

  .design-project video,
  .design-project iframe {
    width: 300px;
    height: 300px;
  }

  .design-project img,
  .design-project video,
  .design-project iframe {
    width: 100%;
    margin: 5px 0px;
  }

  hr {
    margin: 10px 0px;
  }
}