#app {
  display: flex;
  flex-direction: row;
}

#header {
  width: 50%;
}

#component {
  width: 50%;
  margin-left: 75px;
}

@media (max-width: 400px) {
  #app {
    flex-direction: column;
  }

  #header {
    width: 100%;
  }

  #component {
    width: 100%;
    margin: 30px 0px;
  }
}